@import 'styles/main.scss';

.container {
  margin: 36px auto;
  width: 100%;

  .titleContainer {
    margin-top: 26px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .title {
      @include font-32-bold;
      position: relative;
      color: var(--text-color);

      &__counter {
        position: absolute;
        @include font-12-bold;
        color: var(--text-light);
      }

      @media screen and (max-width: 1024px) {
        @include font-22-bold;
        color: var(--text-color);

        &__counter {
          @include font-12-bold;
        }
      }
    }

    .filterIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 80px;
      background: var(--accent-light);
      cursor: pointer;

      svg {
        path {
          stroke: var(--holder-color);
        }
      }
    }
  }

  .filterBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    max-width: 100%;

    @media screen and (max-width: $xs) {
      overflow-x: auto;
    }

    .multiselectWrapper {
      width: 220px;
      cursor: pointer;
      height: 38px;
      margin-top: 24px;

      &__dropdown {
        border-radius: 20px;
        border: 1px solid var(--accent-second);

        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          background-color: var(--accent-third);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--accent-second);
        }
      }

      &__input {
        border-color: var(--accent-second) !important;
        border-radius: 20px !important;
        background: var(--base-white) !important;
        height: 38px !important;

        &:hover {
          border: 1px solid var(--accent-second);
          background: #ffffff !important;
        }
      }
    }

    .mainButton {
      height: 38px;
      margin-top: 24px;

      @include font-14-bold;

      @media screen and (max-width: 1024px) {
        position: fixed;
        width: calc(100% - 36px);
        bottom: 72px;
        z-index: 1;
        left: 0;
        margin: 0 20px;
      }

      .plusIcon {
        display: flex;
        margin-right: 10px;

        svg {
          stroke: #ffffff;
          width: 20px;
          height: 20px;
        }
      }

      &:disabled {
        color: $white;
        background-color: rgba(var(--main-rgb), 0.5);
      }
    }
  }

  .cardBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 100px;
    }

    @media screen and (max-width: 768px) {
      margin: 0px -20px 120px -20px;
    }

    .emptyBlock {
      width: 100%;
      border-radius: 36px;
      padding: 92px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // min-height: 365px;
      justify-content: center;

      &__title {
        h1 {
          @include font-32-bold;
          text-align: center;
          margin: 0;
          margin-bottom: 16px;
          color: var(--text-color);
        }
      }
      &__description {
        p {
          @include font-14;
          text-align: center;
          color: var(--text-color);
          margin: 0;
        }
      }
      &__button {
        .mainButton {
          height: 38px;
          margin-top: 40px;

          @include font-14-bold;

          .plusIcon {
            display: flex;
            margin-right: 10px;

            svg {
              stroke: #ffffff;
              width: 20px;
              height: 20px;
            }
          }

          @media screen and (max-width: 1024px) {
          }

          &:disabled {
            color: $white;
            background-color: rgba(var(--main-rgb), 0.5);
          }
        }
      }

      @media (max-width: 1024px) {
        height: 261px;

        &__title {
          h1 {
            width: 100%;
            font-size: 22px;
            margin-bottom: 8px;
          }
        }

        &__description {
          width: 100%;
          p {
            @include font-14;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;

  &__top--center {
    left: 50% !important;
  }
}

.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;

  .mainButton {
    background-color: var(--accent-light);
    color: var(--accent-color);
  }

  &:hover {
    background-color: var(--accent-light);
    color: var(--accent-color);
  }
}


.attention {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--ui-03-8);
  margin-bottom: 24px;
  max-width: 1440px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }
  }

  &__title {
    @include font-14-bold;
    font-weight: 700;
    color: var(--text-color);
  }

  &__description {
    @include font-12;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__link {
    cursor: pointer;
    color: var(--accent-color);
  }
}

.lineBreak {
  display: block; 
  
  @media (max-width: 768px) { 
    display: inline; 
  }
}
