@import 'styles/helpers/variables.scss';

.sticker {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: 36px;
  max-width: max-content;

  &__text {
    @include font-11-bold;
    letter-spacing: 0.24px;
    // text-transform: uppercase;
  }

  &_purple {
    color: #BC6EF3;
    background: #BC6EF314;

    &_global {
      color: #8362F3;
      background: #8362F314;
    }
  }


  &_yellow {
    background-color: rgba(#fab005, 0.08);
    color: #fab005;
  }

  &_grey {
    color: var(--holder-color);
    background: var(--text-light-8);
  }

  &_green {
    background-color: rgba(#37b24d, 0.08);
    color: #37b24d;
  }

  &_red {
    background-color: rgba(#f14b4b, 0.08);
    color: var(--error-color);
  }
}
