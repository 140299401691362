@import "styles/main.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d09188f;
  z-index: 1;
  animation: fadeIn 0.3s ease-out;
}

.overlay.close {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 32px 20px 90px 20px;
  z-index: 1;
  animation: slideUp 0.3s ease-out;

  .titleHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--text-color);
  }

  .title {
    @include font-20-bold;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &__multi {
      @include font-18-bold;
      padding-bottom: 28px;
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .description {
    @include font-14;
    color: var(--holder-color);
  }

  .subHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    margin-bottom: 12px;

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__text {
      @include font-14-bold;
      color: var(--text-color);
    }

    &__count {
      @include font-12-bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 6px 2px 8px;
      background: var(--accent-light);
      color: var(--accent-color);
      border-radius: 36px;
      gap: 2px;

      &__icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: flex;

        svg {
          fill: var(--accent-color);
          padding: 3px;
        }
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__isMulti {
      gap: 8px;
      margin-bottom: 20px;
    }

    .button {
      display: flex;
      @include font-16;
      padding: 12px;
      text-align: left;
      color: var(--text-color);
      border-bottom: 1px solid var(--accent-third);
      border-radius: 0;
      flex-direction: column;
      align-items: flex-start;

      &.priceName {
        font-size: 14px;
      }

      .price {
        @include font-16-bold;
        color: var(--text-color);
        margin-top: 8px;
      }

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  &.close {
    animation: slideDown 0.3s ease-out;
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 8px;

    .bottomBlock {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__button {
        padding: 12px 32px;
        width: 100%;
      }
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
