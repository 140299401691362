@import '/src/styles/helpers/variables.scss';

.politic-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 10px;

  &__link {
    @include font-12;
    color: var(--text-light);
    transition: $transition;

    &:hover {
      color: var(--text-color);
    }

    &__auth {
      color: var(--accent-light);
    }
  }

  &__ru {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 40px;
    row-gap: 6px;
    max-width: 520px;
    align-items: start;

    @media screen and (max-width: $phone) {
      grid-template-columns: 1fr;
      align-items: flex-start;
    }
  }
}
