@import 'styles/main.scss';

.dots {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }

  .table__search {
    background: none;
    border: none;
  }

  .delete {
    cursor: pointer;
    color: var(--error-color);
  }
}

.inner {
  max-width: 100%;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  will-change: transform;

  @media screen and (min-width: calc($xs-up + 1px)) {
    height: unset;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }

  .headerWrapper {
    .title {
      padding-right: 15px;
      @include font-18-bold;
      margin: 0 0 24px 0;
      pointer-events: none;
      text-decoration: none;

      a {
        pointer-events: none;
        text-decoration: none;
      }

      @media screen and (max-width: $xs) {
        @include font-18-bold;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.supervisorHeaderWrapper {
      .title {
        margin: 0;
      }
    }

    &__supervisor {
      margin-bottom: 64px;
    }

    .titleEmail {
      @include font-14;
      color: var(--text-light);
      margin: 0 0 24px;
      pointer-events: none;
      text-decoration: none;

      a {
        pointer-events: none;
        text-decoration: none;
      }
    }

    .titleWithoutId {
      margin-bottom: 24px;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .button {
      width: 100%;
    }

    @media screen and (max-width: $xs-up) {
      flex-direction: column;
    }
  }
}

.titleRemove {
  @include font-14;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-line-clamp: 100;
  line-clamp: 100;
}

.title-description-remove {
  color: var(--text-light);

  @include font-12;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.innerDelete {
  padding: 24px !important;
  margin: 0;

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  .buttonsDelete {
    gap: 12px;
  }
}

.chooseContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;

  .btnContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .errorText {
      @include font-12;
      color: var(--error-color);
      margin-left: 16px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    width: 100%;
    height: 55px;
    background-color: transparent;
    color: var(--text-color) !important;
    padding: 8px 16px;
    @include font-14;
    border: 40px;

    .btnInner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;

      &__full {
        .btnInner__title {
          @include font-14-bold;
          display: flex;

          .redDot {
            display: flex;
            width: 4px;
            height: 4px;
            border-radius: 100px;
            background-color: var(--accent-color);
            margin-left: 4px;
            margin-top: 4px;
          }
        }
        .btnInner__value {
          @include font-11;
          color: var(--text-light);
        }
      }
    }

    .icon {
      display: flex;
      align-items: center;
    }

    &.error {
      border: 1px solid var(--error-color);
    }

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);

      path {
        fill: var(--accent-second);
      }
    }

    &:hover {
      opacity: unset;
    }

    &.active {
      background-color: var(--accent-light);
    }
  }
}

.supervisor__titleEmail {
  margin-bottom: unset;
  margin-top: 2px;
  @include font-14;
  color: var(--holder-color);
}

.supervisor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  height: 56px;
  background-color: transparent;
  color: var(--text-color) !important;
  padding: 8px 16px;
  @include font-14-bold;
  margin-bottom: 28px;
  box-shadow: var(--preview-box-shadow);
  border-radius: 28px;

  &__btn {
    padding: 0;
    width: 100%;
  }

  &__chevronIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: var(--holder-color);
    }
  }

  &__title {
    width: 100%;
    color: var(--text-color);
  }

  &__crossIcon {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 3px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}