@import 'styles/helpers/variables.scss';

.header {
  display: grid;
  grid-template-columns: 30px 380px 260px 258px 160px 160px;
  background: var(--accent-light);
  border-bottom: 1px solid var(--accent-third);
  width: 100%;

  @media (max-width: 1350px) {
    grid-template-columns: 30px 2fr 2fr 1fr 1fr 1fr;
  }

  &__supervisor {
    grid-template-columns: 30px 300px 260px 140px 230px 128px 160px;

    @media (max-width: 1350px) {
      grid-template-columns: 30px 2fr 2fr 1fr 1fr 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 12px 16px;
    &:first-child {
      padding: 8px 12px;
      justify-content: center;
    }

    &:nth-child(3) {
      overflow: unset;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    border: 1px solid var(--accent-color);
    background-color: var(--accent-color);
    svg {
      width: 14px;
      height: 14px;
      stroke: $white;
    }
  }

  &__title {
    display: inline-block;
    color: var(--text-color);
    width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    @include font-12-bold;
  }

  &__statuses {
    display: flex;
    color: var(--holder-color);
    justify-content: space-between;
    width: 100%;

    &--item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      width: 25%;

      &_first {
        svg {
          width: 17px;
          height: 17px;

          path {
            &:first-child {
              fill: var(--holder-color);
            }

            &:last-child {
              stroke: var(--holder-color);
            }
          }
        }
      }

      &_second {
        svg {
          width: 16px;
          height: 16px;
          stroke: var(--holder-color);
        }
      }

      &_third {
        svg {
          width: 14px;
          height: 14px;
          fill: var(--holder-color);
        }
      }

      &_fourth {
        svg {
          width: 17px;
          height: 17px;

          path {
            &:first-child {
              fill: var(--holder-color);
            }
            &:nth-child(2) {
              fill: var(--accent-light);
            }
            &:nth-child(3),
            &:nth-child(4) {
              stroke: var(--holder-color);
            }
          }
        }
      }
    }
  }

  &__projects,
  &__cost {
    color: var(--holder-color);
  }

  .tooltipContainer {
    position: relative;
    max-height: 17px;
    display: flex;
  }

  .tooltip {
    z-index: 100;
    min-width: unset;
    color: $white;
    box-shadow:
      0px 15px 125px 0px rgba(198, 180, 250, 0.13),
      0px 4.522px 37.684px 0px rgba(215, 180, 250, 0.12);
    border-radius: 8px;
    transition: 0.3s;

    :global(.common__tooltip-inner) {
      padding: 6px 12px;
      @include font-11-bold;
      background: #615a6d;
      &::after {
        top: -14px;
        border: 8px solid rgba(0, 0, 0, 0);
        border-top: 6px solid #615a6d;
      }
    }
  }
}
