@import 'styles/helpers/variables.scss';

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
}

.text {
  margin: 0;
}

.title {
  margin-bottom: 16px;
  text-align: center;

  @media screen and (max-width: $xs-up) {
    h2 {
      font-size: 24px;
    }
  }
}

.subtitle {
  margin-bottom: 48px;
  text-align: center;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 40px;
    h3 {
      font-size: 16px;
    }
  }
}

.wrap {
  margin-top: 138px;

  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -60px;
    left: 0;
    background-image: var(--notfound-grad);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $xs-up) {
      background-image: url('/assets/img/System/gradient-mobile.png');
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: var(--notfound-img);
    background-position: center 180px;
    background-repeat: no-repeat;
    z-index: 1;

    @media screen and (max-width: $xs-up) {
      max-width: 90%;
      left: 0;
      right: 0;
      margin: auto;
      background-size: contain;
      background-position: center 100px;
    }
  }

  @media screen and (max-width: $xs-up) {
    margin-top: 80px;
  }
}

.button {
  color: $white;
}

.img {
  position: fixed;
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: 60px;

  @media screen and (max-width: $xs-up) {
    bottom: 102px;
  }
}

.mobile {
  display: none;

  @media screen and (max-width: $xs-up) {
    display: block;
  }
}

.desktop {
  display: block;

  @media screen and (max-width: $xs-up) {
    display: none;
  }
}
