@import '/src/styles/helpers/variables.scss';

.wrapper {
  display: flex;
  gap: 7px;
  align-items: center;

  @media screen and (min-width: 576px) {
    gap: 17px;
  }
}

.wrapper-pagination {
  display: flex;
  gap: 4px;

  @media screen and (min-width: 576px) {
    gap: 8px;
  }
}

.item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 104px;
  background-color: var(--accent-light);
  color: var(--accent-color);
  cursor: pointer;
  @include font-12-bold;
}

.item_active {
  border-radius: 104px;
  background-color: var(--accent-color);
  color: $white;
  pointer-events: none;
}

.symbol {
  background-color: transparent;
  pointer-events: none;
}

.button {
  all: unset;
  cursor: pointer;

  &_disabled {
    color: var(--gray-color);
    cursor: default;
  }
}
