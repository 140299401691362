@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  min-height: 40px;

  &.errorWrapper {
    .error {
      background: var(--error-bg);
      &:hover {
        background: #ffffff;
      }

      input:hover {
        border-color: transparent !important;
        background: transparent !important;
      }
    }

    .errorText {
      font-size: 12px;
      margin-top: 4px;
      color: #ea4a70;
    }
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  svg {
    height: 35px !important;
    width: 35px !important;
  }
}

.main-input {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 40px;
  margin-bottom: 4px;
  padding: 11px 12px;
  border-radius: 8px;
  border: 1px solid var(--accent-second);
  background: var(--accent-light);

  &__disabled {
    background: var(--accent-light-disabled) !important;
    &:hover {
      border: 1px solid var(--accent-second);
    }
  }

  &__disabled * {
    cursor: default !important;
  }

  &__solutionForm {
    border-radius: 12px;
    border-color: transparent;
    min-height: 56px;
    height: unset;
    padding: 8px 12px;

    &:hover {
      border: 1px solid var(--accent-second);
      background: #ffffff;
    }

    .arrow {
      margin: 0 !important;
    }

    &.error {
      &:hover {
        border-color: var(--error-border-hover);
      }

      &.main-input__active {
        border: 1px solid var(--error-color);
        background: #ffffff;
      }
    }

    .endIcon {
      display: flex;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: var(--gray-color);
          stroke-width: 1.3px;
        }
      }

      &.errorIcon {
        path {
          stroke: var(--error-color);
        }
      }
    }
  }

  &__accountForm {
    background: var(--accent-light);
    border-radius: 12px;

    &.main-input__disabled {
      background: var(--accent-light-disabled) !important;
      &:hover {
        border: 1px solid transparent !important;
      }
    }
  }

  &__withTitle {
    border-color: transparent;
    background: var(--accent-light);
  }

  &:hover {
    border-color: var(--accent-second);
    background: #ffffff;
  }

  &__active {
    border-color: var(--accent-color);
    background: #ffffff !important;
  }

  .titleBlock {
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    gap: 2px;

    &__disabled {
      opacity: 0.5;
    }

    .inputTitle {
      color: var(--holder-color);
      @include font-14;
      &Selected {
        @include font-12;
      }
    }

    @media (max-width: 1024px) {
      width: 80%;
    }
  }

  .basicRowValue {
    &__item {
      white-space: nowrap;
    }
  }

  &--content {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 90%;
    gap: 4px 6px;
    margin-right: 8px;

    &.multiline {
      width: unset;
      flex-wrap: wrap;
      margin: 0;

      @media screen and (max-width: 768px) {
        width: 120%;
      }
    }
  }

  &--placeholder {
    display: inline-block;

    @include font-14;
    min-width: 10px;
    max-width: 80%;
    max-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    color: var(--text-light);
    text-overflow: ellipsis;

    @media screen and (max-width: $xs) {
      @include font-16;
    }
  }

  &--dots {
    color: var(--text-light);
    margin-right: 8px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 100%;
    width: 20px;

    transform: translate(1px, 2px);
    cursor: pointer;

    svg {
      width: 13px;
      height: 8px;
      transform: scale(1.2);

      path {
        fill: var(--gray-color);
      }
    }
  }

  .arrowActive {
    transform: translateY(-2px) rotate(180deg);
  }
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: $white;
  box-shadow: var(--select-box-shadow);
  border-radius: 8px;

  .startIcon {
    display: flex;
    align-items: center;

    svg {
      fill: var(--holder-color);
      opacity: 1;
    }
  }

  .enter-source {
    position: relative;
    margin: 16px 16px 0;

    &__form {
      margin: 4px 4px 0;
    }

    &__input {
      @include font-14;

      &::placeholder,
      input::placeholder {
        @include font-14;
      }
      padding-left: 2px !important;
    }
  }

  .list {
    padding: 0;
    margin: 4px 0;
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;

    &__solutionForm {
      min-height: auto;
    }

    &__checkAll {
      display: flex;
      flex-direction: column;

      &__allItem {
        @include font-16;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 8px;
        gap: 8px;
        border-radius: 4px;
        cursor: pointer;
      }

      &__items {
        padding-left: 16px;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-third);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-second);
    }

    .emptyState {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: inherit;

      &__icon {
        svg {
          width: 96px;
          height: 50px;
        }
      }

      &__text {
        @include font-12;
        color: var(--text-light);
      }
    }
  }
}

.fake-input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  height: 0;
  padding: 0;
  border: none;
}

.outside__tag {
  padding: 2px 8px;
  border-radius: 36px;
  border: 1px solid var(--accent-color);
  background: var(--accent-light);
  color: var(--accent-color);
  @include font-11;
  text-align: center;

  &__ru {
    padding: 0 8px 2px 7px;
    display: flex;
    align-items: center;

    &__plus {
      padding-top: 2px;
    }
  }
}
