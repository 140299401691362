@import 'styles/helpers/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  border-radius: 36px;
  background: $white;
  box-shadow:
    0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12),
    0px 15px 125px 0px rgba(201, 180, 250, 0.13);
  padding: 24px 20px;

  @media screen and (max-width: 680px) {
    margin: 24px -20px 0px -20px;
  }
}

.accordion__header {
  background: var(--accent-light);
  border-radius: 8px;
}

.project {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 8px;
  border-bottom: 1px solid var(--accent-light);

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__link {
    @include font-12;
    a {
      display: flex;
      align-items: center;
      gap: 2px;
      color: var(--accent-color);
      span {
        max-width: 138px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__title {
    @include font-18-bold;

    a {
      color: var(--text-color);
      &:hover {
        color: var(--accent-color);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 8px;
    @include font-11;
    color: var(--holder-color);

    span {
      display: flex;
      align-items: center;
      gap: 6px;
      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: var(--gray-second);
      }
    }
  }

  &__id {
    @include font-11;
    color: var(--holder-color);
  }

  &__businessUnit {
    @include font-11;
    color: var(--holder-color);
  }

  &__cost {
    margin-top: 12px;
    @include font-24-bold;
    color: var(--text-color);
  }
}

.button {
  padding-top: 20px;
  background: none;
  color: var(--accent-color);
}
